import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home }, //カレンダー
  { path: '/Enterprise', name: 'Enterprise', component: () => import('../views/Enterprise.vue') }, //入力基本
  { path: '/Bumonselect', name: 'Bumonselect', component: () => import('../views/Bumonselect.vue') }, //部門表示
  { path: '/Bumon', name: 'Bumon', component: () => import('../views/Bumon.vue') }, //部門表示・修正・削除・追加
  { path: '/Bumondel', name: 'Bumondel', component: () => import('../views/Bumondel.vue') }, //部門削除 
  { path: '/Bumoninppost', name: 'Bumoninppost', component: () => import('../views/Bumoninppost.vue') }, //部門追加 post
  { path: '/Bumoninput', name: 'Bumoninput', component: () => import('../views/Bumoninput.vue') }, //部門追加 get
  { path: '/Bumonupdate', name: 'Bumonupdate', component: () => import('../views/Bumonupdate.vue') }, //部門更新get
  { path: '/YAKRMst', name: 'YAKRMst', component: () => import('../views/YAKRMst.vue') }, //部門更新get
  { path: '/Iframe', name: 'Iframe', component: () => import('../views/Iframe.vue') }, //iframe
  { path: '/BRMSTS', name: 'BRMSTS', component: () => import('../views/BRMSTS.vue') }, //簡単葬儀分類取得
  { path: '/Whiteboard', name: 'Whiteboard', component: () => import('../views/Whiteboard.vue') }, //ホワイトボード
  { path: '/TerminalSet', name: 'TerminalSet', component: () => import('../views/TerminalSet.vue') }, //端末設定
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
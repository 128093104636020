<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :mini-variant="miniVariant" :clipped="clipped" fixed app>
      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" router exact >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="clipped" fixed app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-btn icon @click.stop="miniVariant = !miniVariant">
        <v-icon>mdi-{{ `chevron-${miniVariant ? 'right' : 'left'}` }}</v-icon>
      </v-btn>
      <v-btn icon @click.stop="clipped = !clipped">
        <v-icon>mdi-application</v-icon>
      </v-btn>
      <v-btn icon @click.stop="fixed = !fixed">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <v-btn icon @click.stop="rightDrawer = !rightDrawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- メイン -->
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-navigation-drawer v-model="rightDrawer" :right="right" temporary fixed>
      <v-list>
        <v-list-item @click.native="right = !right">
          <v-list-item-action>
            <v-icon light>mdi-repeat</v-icon>
          </v-list-item-action>
          <v-list-item-title>Switch drawer (click me)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- フッターナビ -->
    <v-footer>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }}  Business Logic Japan.,co.Ltd. AII Rights Reserved.
        </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data () {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      //サイドナビ 
      items: [
        {icon: 'mdi-apps',title: '施行カレンダー',to: '/'},
        {icon: 'mdi-chart-bubble',title: '入力基本',to: '/Enterprise'},
        {icon: 'mdi-chart-bubble',title: '部門一覧修正',to: '/Bumon'},
        {icon: 'mdi-chart-bubble',title: '経路一覧',to: '/YAKRMst'},
        {icon: 'mdi-chart-bubble',title: '部門一覧',to: '/Bumonselect'},
        {icon: 'mdi-chart-bubble',title: '部門入力',to: '/Bumoninput'},
        {icon: 'mdi-chart-bubble',title: '部門入力(POST)',to: '/Bumoninppost'},
        {icon: 'mdi-chart-bubble',title: '部門削除',to: '/Bumondel'},
        {icon: 'mdi-chart-bubble',title: '部門更新',to: '/Bumonupdate'},
        {icon: 'mdi-apps',title: 'HP表示',to: '/Iframe'},
        {icon: 'mdi-apps',title: 'ホワイトボード表示',to: '/Whiteboard'},
        {icon: 'mdi-apps',title: '端末設定',to: '/TerminalSet'},
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: '簡単葬儀システム'
    }
  }
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    xBRMSID: ""             //分類マスタコール時のID
  },
 //state 内のデータの状態から算出される値（≒算出プロパティ）
 getters: {
  GET_kigyoucd(state) { return state.xkigyoucd }, //企業コード
  GET_Tanmtid(state){return state.xtanmtid},    //端末ID
  GET_BRMSID(state){return state.xBRMSID},        //分類マスタコール時のIDの更新
},
//state のデータを直接操作するための関数（非同期処理は定義不可）
mutations: {
  increment_kg(state, payload) { state.xkigyoucd  = payload; },  //企業コードの更新
  increment_tn(state, payload) { state.xtanmtid   = payload; },  //端末IDの更新
  increment_nm(state, payload) { state.xusernm    = payload; },  //ユーザー名称の更新
  increment_BRID(state, payload) { state.xBRMSID  = payload; }   //分類マスタコール時のIDの更新
},
//mutations の操作を各コンポーネントから呼び出すために使用する関数（非同期処理を定義可）
actions: {

},
modules: {
}
})